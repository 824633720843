import React, { useState, useEffect } from "react";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Badge } from "primereact/badge";
import { ProgressSpinner } from "primereact/progressspinner";
import config from "../../config";

const LeaderboardDataTable = () => {
  const [syllabuses, setSyllabuses] = useState([]);
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [mediums, setMediums] = useState([]);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [grades, setGrades] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [formData, setFormData] = useState({
    syllabusId: "",
    mediumId: "",
    gradeId: "",
    period: "all",
  });
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSyllabusData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          `${config.apiUrl}/syllabus`,
          headersConfig
        );
        const fetchedSyllabuses = response.data.syllabuses;
        setSyllabuses(fetchedSyllabuses);
        setSyllabusOptions(
          fetchedSyllabuses.map((syllabus) => ({
            label: syllabus.name,
            value: syllabus._id,
          }))
        );
      } catch (error) {
        console.error("Error fetching syllabuses:", error);
      }
    };

    fetchSyllabusData();
  }, []);

  useEffect(() => {
    if (formData.syllabusId) {
      const fetchMediumData = async () => {
        try {
          const token = localStorage.getItem("token");
          const headersConfig = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const selectedSyllabus = formData.syllabusId;
          const response = await axios.get(
            `${config.apiUrl}/medium/bySyllabus?syllabusId=${selectedSyllabus}`,
            headersConfig
          );
          const fetchedMediums = response.data.mediums;
          if (fetchedMediums && fetchedMediums.length > 0) {
            setMediums(fetchedMediums);
            setMediumOptions(
              fetchedMediums.map((medium) => ({
                label: medium.name,
                value: medium._id,
              }))
            );
          } else {
            setMediums([]);
            setMediumOptions([]);
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            setMediums([]);
            setMediumOptions([]);
          } else {
            console.error("Error fetching mediums:", error);
          }
        }
      };

      fetchMediumData();
    }
  }, [formData.syllabusId]);

  useEffect(() => {
    if (formData.syllabusId) {
      const fetchGradeData = async () => {
        try {
          const token = localStorage.getItem("token");
          const headersConfig = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const selectedSyllabus = formData.syllabusId;
          let url = `${config.apiUrl}/grade/bySyllabusAndMedium?syllabusId=${selectedSyllabus}`;

          if (formData.mediumId) {
            url += `&mediumId=${formData.mediumId}`;
          }

          const response = await axios.get(url, headersConfig);
          const fetchedGrades = response.data.grades;
          setGrades(fetchedGrades);
          setGradeOptions(
            fetchedGrades.map((grade) => ({
              label: grade.name,
              value: grade._id,
            }))
          );
        } catch (error) {
          console.error("Error fetching grades:", error);
        }
      };

      fetchGradeData();
    }
  }, [formData.syllabusId, formData.mediumId]);

  const fetchLeaderboardData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const params = {
        syllabusId: formData.syllabusId,
        gradeId: formData.gradeId,
      };
      const response = await axios.get(
        `${config.apiUrl}/leaderboard/${formData.period}`,
        { params, ...headersConfig }
      );
      setLeaderboardData(response.data.leaderboard);
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!formData.syllabusId || !formData.gradeId) {
      setLeaderboardData([]);
    } else {
      fetchLeaderboardData();
    }
  }, [formData]);

  const getPointsColumn = () => {
    switch (formData.period) {
      case "daily":
        return <Column field="dailyPoints" header="Daily Points" />;
      case "weekly":
        return <Column field="weeklyPoints" header="Weekly Points" />;
      case "monthly":
        return <Column field="monthlyPoints" header="Monthly Points" />;
      default:
        return <Column field="totalPoints" header="Total Points" />;
    }
  };

  const renderRanks = (rowData) => {
    return (
      <Badge
        value={rowData.rank}
        style={{ backgroundColor: "#4caf50" }}
      ></Badge>
    );
  };

  const clearFilters = () => {
    setFormData({
      syllabusId: "",
      mediumId: "",
      gradeId: "",
      period: "all",
    });
  };

  return (
    <div>
      <h3
        style={{
          float: "left",
          color: "#808080",
        }}
      >
        Leaderboard Rankings
      </h3>
      <br />
      <br />
      <br />
      <br />
      <div
        className="filters-container"
        style={{ alignItems: "center", marginBottom: "20px" }}
      >
        <div className="filter-row">
          <Dropdown
            value={formData.syllabusId}
            options={syllabusOptions}
            onChange={(e) => setFormData({ ...formData, syllabusId: e.value })}
            placeholder="Select Syllabus"
            style={{ marginRight: "10px" }}
          />
        </div>
        <div className="filter-row">
          <Dropdown
            value={formData.mediumId}
            options={mediumOptions}
            onChange={(e) => setFormData({ ...formData, mediumId: e.value })}
            placeholder="Select Medium"
            style={{ marginRight: "10px" }}
          />
        </div>
        <div className="filter-row">
          <Dropdown
            value={formData.gradeId}
            options={gradeOptions}
            onChange={(e) => setFormData({ ...formData, gradeId: e.value })}
            placeholder="Select Grade"
            style={{ marginRight: "10px" }}
          />
        </div>
        <div className="filter-row">
          <RadioButton
            value="all"
            name="leaderboardPeriod"
            checked={formData.period === "all"}
            onChange={(e) => setFormData({ ...formData, period: e.value })}
            inputId="all"
          />
          &nbsp;&nbsp;
          <label htmlFor="all">All Time</label>&nbsp;&nbsp;
          <RadioButton
            value="daily"
            name="leaderboardPeriod"
            checked={formData.period === "daily"}
            onChange={(e) => setFormData({ ...formData, period: e.value })}
            inputId="daily"
          />
          &nbsp;&nbsp;
          <label htmlFor="daily">Daily</label>&nbsp;&nbsp;
          <RadioButton
            value="weekly"
            name="leaderboardPeriod"
            checked={formData.period === "weekly"}
            onChange={(e) => setFormData({ ...formData, period: e.value })}
            inputId="weekly"
          />
          &nbsp;&nbsp;
          <label htmlFor="weekly">Weekly</label>&nbsp;&nbsp;
          <RadioButton
            value="monthly"
            name="leaderboardPeriod"
            checked={formData.period === "monthly"}
            onChange={(e) => setFormData({ ...formData, period: e.value })}
            inputId="monthly"
          />
          &nbsp;&nbsp;
          <label htmlFor="monthly">Monthly</label>
        </div>
        <div className="filter-row" style={{ marginLeft: "50px" }}>
          <Button
            icon="pi pi-filter-slash"
            className="p-button-rounded"
            style={{
              backgroundColor: "#708db6",
              border: "none",
              borderColor: "#708db6",
              color: "#ffffff",
              fontSize: "0.5rem",
            }}
            onClick={clearFilters}
          />
        </div>
      </div>
      {/* <div className="p-d-flex p-jc-between p-mt-3" style={{ float: "right" }}>
        <Button
          label={
            loading ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                Loading...&nbsp;&nbsp;
                <ProgressSpinner
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                />
              </div>
            ) : (
              "Load Leaderboard"
            )
          }
          icon="pi pi-sync"
          onClick={fetchLeaderboardData}
          disabled={!formData.syllabusId || !formData.gradeId}
        />
        &nbsp;
      </div> */}
      <br />
      <br />
      <DataTable
        value={leaderboardData}
        className="p-mt-3"
        style={{ marginBottom: "20px" }}
      >
        <Column field="country" header="Country" />
        <Column field="name" header="Name" />
        <Column field="email" header="Email" />
        <Column field="phone" header="Phone" />
        {getPointsColumn()}
        <Column field="rank" header="Rank" body={renderRanks} />
      </DataTable>
    </div>
  );
};

export default LeaderboardDataTable;
