import React, { useState, useEffect } from "react";
import axios from "axios";
import { ProgressSpinner } from "primereact/progressspinner";
import UsersByGradeChart from "../Charts/UsersByGradeChart";
import QuestionCountBySyllabusChart from "../Charts/QuestionCountBySyllabusChart";
import PaperCountByGradeChart from "../Charts/PaperCountByGradeChart";
import MobileUserCountByCountryChart from "../Charts/MobileUserCountByCountryChart";
import PlayCountLineChart from "../Charts/PlayCountLineChart";
import { Dropdown } from "primereact/dropdown";
import config from "../../config";
import "./Dashboard.css";

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalQuestionCount: 0,
    totalPaperCount: 0,
    totalMobileUserCount: 0,
    totalPlayCount: 0,
    totalSubscriptionCount: 0,
  });
  const currentUserType = localStorage.getItem("userType");
  const isNotAdmin = currentUserType !== "admin";
  const currentUserName = localStorage.getItem("userName");

  const [selectedSyllabus, setSelectedSyllabus] = useState(null);
  const [selectedMedium, setSelectedMedium] = useState(null);
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [mediumOptions, setMediumOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        if (isNotAdmin) {
          const res = await axios.get(
            `${config.apiUrl}/common/for-user-counts?currentUserName=${currentUserName}`,
            headersConfig
          );

          setStats({
            totalQuestionCount: res.data.totalQuestionCount,
            totalPaperCount: res.data.totalPaperCount,
            totalMobileUserCount: 0,
            totalPlayCount: 0,
            totalSubscriptionCount: 0,
          });
        } else {
          const res = await axios.get(
            `${config.apiUrl}/common/counts`,
            headersConfig
          );

          setStats({
            totalQuestionCount: res.data.totalQuestionCount,
            totalPaperCount: res.data.totalPaperCount,
            totalMobileUserCount: res.data.totalMobileUserCount,
            totalPlayCount: res.data.totalPlayCount,
            totalSubscriptionCount: res.data.totalSubscriptionCount,
          });
        }
      } catch (error) {
        console.error("Error fetching stats", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [isNotAdmin, currentUserName]);

  useEffect(() => {
    const fetchSyllabusData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          `${config.apiUrl}/syllabus`,
          headersConfig
        );
        const fetchedSyllabuses = response.data.syllabuses;
        setSyllabusOptions(
          fetchedSyllabuses.map((syllabus) => ({
            label: syllabus.name,
            value: syllabus._id,
          }))
        );
      } catch (error) {
        console.error("Error fetching syllabuses:", error);
      }
    };

    fetchSyllabusData();
  }, []);

  useEffect(() => {
    if (selectedSyllabus) {
      const fetchMediumData = async () => {
        try {
          const token = localStorage.getItem("token");
          const headersConfig = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await axios.get(
            `${config.apiUrl}/medium/bySyllabus?syllabusId=${selectedSyllabus}`,
            headersConfig
          );
          const fetchedMediums = response.data.mediums;
          setMediumOptions(
            fetchedMediums.map((medium) => ({
              label: medium.name,
              value: medium._id,
            }))
          );
        } catch (error) {
          if (error.response && error.response.status === 404) {
            setMediumOptions([]);
          } else {
            console.error("Error fetching mediums:", error);
          }
        }
      };

      fetchMediumData();
    } else {
      setMediumOptions([]);
    }
  }, [selectedSyllabus]);

  return (
    <div className="dashboard" style={{ marginBottom: "50px" }}>
      {loading ? (
        <center>
          <ProgressSpinner
            style={{ width: "36px", height: "36px" }}
            strokeWidth="5"
            animationDuration=".5s"
          />
        </center>
      ) : (
        <>
          <div className={`cards-container ${isNotAdmin ? "not-admin" : ""}`}>
            <div className="dashboard-card">
              <div className="card-title">
                <span className="main-content">QUESTIONS</span>
                <i className="pi pi-question card-icon"></i>
              </div>
              <hr className="custom-hr"></hr>
              <p className="card-value">{stats.totalQuestionCount}</p>
            </div>
            <div className="dashboard-card">
              <div className="card-title">
                <span className="main-content">PAPERS</span>
                <i className="pi pi-file card-icon"></i>
              </div>
              <hr className="custom-hr"></hr>
              <p className="card-value">{stats.totalPaperCount}</p>
            </div>
            {!isNotAdmin && (
              <>
                <div className="dashboard-card">
                  <div className="card-title">
                    <span className="main-content">MOBILE USERS</span>
                    <i className="pi pi-users card-icon"></i>
                  </div>
                  <hr className="custom-hr"></hr>
                  <p className="card-value">{stats.totalMobileUserCount}</p>
                </div>
                <div className="dashboard-card">
                  <div className="card-title">
                    <span className="main-content">PLAYS</span>
                    <i className="pi pi-play card-icon"></i>
                  </div>
                  <hr className="custom-hr"></hr>
                  <p className="card-value">{stats.totalPlayCount}</p>
                </div>
                <div className="dashboard-card">
                  <div className="card-title">
                    <span className="main-content">SUBSCRIPTIONS</span>
                    <i className="pi pi-play card-icon"></i>
                  </div>
                  <hr className="custom-hr"></hr>
                  <p className="card-value">{stats.totalSubscriptionCount}</p>
                </div>
              </>
            )}
          </div>
          {!isNotAdmin && (
            <>
              {/* <div style={{ marginTop: "50px", marginBottom: "20px" }}>
            <Dropdown
              value={selectedSyllabus}
              options={syllabusOptions}
              onChange={(e) => setSelectedSyllabus(e.value)}
              placeholder="Select Syllabus"
              style={{ marginRight: "10px", width: "200px" }}
            />
            <Dropdown
              value={selectedMedium}
              options={mediumOptions}
              onChange={(e) => setSelectedMedium(e.value)}
              placeholder="Select Medium"
              style={{ width: "200px" }}
            />
          </div> */}
              <div>
                <UsersByGradeChart
                // syllabusId={selectedSyllabus}
                // mediumId={selectedMedium}
                />
                <br />
                <PaperCountByGradeChart
                // syllabusId={selectedSyllabus}
                // mediumId={selectedMedium}
                />
                <PlayCountLineChart />
                <MobileUserCountByCountryChart />
              </div>
              <div style={{ display: "flex" }}>
                <QuestionCountBySyllabusChart />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Dashboard;
