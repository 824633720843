import LeaderboardDataTable from "../Charts/LeaderboardDataTable";

const Leaderboard = () => {
  return (
    <div>
      <LeaderboardDataTable />
    </div>
  );
};

export default Leaderboard;
