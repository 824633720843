import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import axios from "axios";
import config from "../../config";

const DBUsersByGradeChart = () => {
  const [syllabuses, setSyllabuses] = useState([]);
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [mediums, setMediums] = useState([]);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [formData, setFormData] = useState({
    syllabusId: "",
    mediumId: "",
  });
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSyllabusData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          `${config.apiUrl}/syllabus`,
          headersConfig
        );
        const fetchedSyllabuses = response.data.syllabuses;
        setSyllabuses(fetchedSyllabuses);
        setSyllabusOptions(
          fetchedSyllabuses.map((syllabus) => ({
            label: syllabus.name,
            value: syllabus._id,
          }))
        );
      } catch (error) {
        console.error("Error fetching syllabuses:", error);
      }
    };

    fetchSyllabusData();
  }, []);

  useEffect(() => {
    if (formData.syllabusId) {
      const fetchMediumData = async () => {
        try {
          const token = localStorage.getItem("token");
          const headersConfig = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const selectedSyllabus = formData.syllabusId;
          const response = await axios.get(
            `${config.apiUrl}/medium/bySyllabus?syllabusId=${selectedSyllabus}`,
            headersConfig
          );
          const fetchedMediums = response.data.mediums;
          if (fetchedMediums && fetchedMediums.length > 0) {
            setMediums(fetchedMediums);
            setMediumOptions(
              fetchedMediums.map((medium) => ({
                label: medium.name,
                value: medium._id,
              }))
            );
          } else {
            setMediums([]);
            setMediumOptions([]);
          }
          setFormData((prevData) => ({
            ...prevData,
            mediumId: null,
          }));
        } catch (error) {
          if (error.response && error.response.status === 404) {
            setMediums([]);
            setMediumOptions([]);
          } else {
            console.error("Error fetching mediums:", error);
          }
        }
      };

      fetchMediumData();
    }
  }, [formData.syllabusId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        let res;
        let url;

        if (formData.syllabusId) {
          url = `${config.apiUrl}/common/by-grade/db/total-users?syllabusId=${formData.syllabusId}`;
          if (formData.mediumId) {
            url += `&mediumId=${formData.mediumId}`;
          }
        } else {
          url = `${config.apiUrl}/common/by-grade/db/total-users`;
        }

        res = await axios.get(url, headersConfig);

        const usersByGrade = res.data.usersByGrade;
        const labels = usersByGrade.map((item) => item.grade);
        const data = usersByGrade.map((item) => item.count);

        setChartData({
          labels,
          datasets: [
            {
              label: "Mobile Users",
              backgroundColor: ["#4caf50", "#708db6"],
              data,
              barPercentage: 1,
              categoryPercentage: 0.5,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [formData.syllabusId, formData.mediumId]);

  const chartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const handleClearAllFilters = () => {
    setFormData({
      syllabusId: null,
      mediumId: null,
    });
    setMediumOptions([]);
  };

  return (
    <div className="card">
      <h3
        style={{
          color: "#808080",
          marginTop: "40px",
          marginBottom: "40px",
        }}
      >
        Mobile Users by Grade
      </h3>
      <div
        className="filters-container"
        style={{ alignItems: "center", marginBottom: "40px" }}
      >
        <div className="filter-row">
          <Dropdown
            value={formData.syllabusId}
            options={syllabusOptions}
            onChange={(e) => setFormData({ ...formData, syllabusId: e.value })}
            placeholder="Select Syllabus"
            style={{ marginRight: "10px" }}
          />
        </div>
        <div className="filter-row">
          <Dropdown
            value={formData.mediumId}
            options={mediumOptions}
            onChange={(e) => setFormData({ ...formData, mediumId: e.value })}
            placeholder="Select Medium"
            style={{ marginRight: "10px" }}
          />
        </div>
        <Button
          icon="pi pi-filter-slash"
          className="p-button-rounded"
          style={{
            background: "none",
            border: "none",
            borderColor: "#708db6",
            color: "#708db6",
            fontSize: "0.5rem",
            float: "right",
          }}
          onClick={handleClearAllFilters}
        />
      </div>
      {loading ? (
        <center>
          {loading && (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="5"
              animationDuration=".5s"
            />
          )}
        </center>
      ) : (
        <div style={{ width: "1000px", marginBottom: "40px" }}>
          <Chart type="bar" data={chartData} options={chartOptions} />
        </div>
      )}
    </div>
  );
};

export default DBUsersByGradeChart;
