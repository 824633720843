import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import { ProgressSpinner } from "primereact/progressspinner";
import config from "../../config";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../store/actions";
import loginImage from "../../assests/login-image.png";

const AdminLoginForm = ({ admin }) => {
  const [formData, setFormData] = useState(
    admin || {
      email: "",
      password: "",
    }
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef(null);

  const navigateToDashboard = () => {
    navigate("/dashboard");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${config.apiUrl}/user/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to log in");
      }

      if (!data.token) {
        throw new Error("No token found in response");
      }
      localStorage.setItem("token", data.token);
      localStorage.setItem("userId", data.data._id);
      localStorage.setItem("userName", data.data.name);
      localStorage.setItem("userType", data.data.userType);
      dispatch(
        loginSuccess({
          token: data.token,
          userId: data.data._id,
          userName: data.data.name,
          userType: data.data.userType,
        })
      );
      navigateToDashboard();
      // window.location.reload();
    } catch (error) {
      console.error("Error:", error.message);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div style={{ width: "45%", float: "left" }}>
        <h1
          style={{
            color: "darkgrey",
            fontSize: "40px",
            fontWeight: "bolder",
            fontFamily: "cursive",
            paddingLeft: "20px",
          }}
        >
          ExamHUB
        </h1>
        <h3
          style={{
            color: "#4caf50",
            paddingLeft: "20px",
            marginTop: "-15px",
          }}
        >
          Welcome back
        </h3>
        <h2
          style={{
            color: "#708db6",
            fontWeight: "bold",
            paddingLeft: "20px",
            marginTop: "25px",
          }}
        >
          Admin Login
        </h2>
        <div className="p-fluid" style={{ padding: "20px" }}>
          <div className="p-field" style={{ marginBottom: "20px" }}>
            <label htmlFor="email" style={{ color: "#708db6" }}>
              Email
            </label>
            <InputText
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              style={{ marginTop: "10px" }}
            />
          </div>
          <div className="p-field" style={{ marginBottom: "20px" }}>
            <label htmlFor="password" style={{ color: "#708db6" }}>
              Password
            </label>
            <Password
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              style={{ marginTop: "10px" }}
              toggleMask
              feedback={false}
            />
          </div>
        </div>
        <div
          className="p-dialog-footer"
          style={{ float: "right", marginTop: "20px", paddingRight: "20px" }}
        >
          <Button
            label={
              loading ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ProgressSpinner
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                  />
                </div>
              ) : (
                "Login"
              )
            }
            onClick={handleSubmit}
            severity="info"
            outlined
          />
        </div>
        <Toast ref={toast} />
      </div>
      <div
        style={{
          width: "55%",
          float: "right",
          height: "100vh",
          marginTop: "-100px",
          marginRight: "-50px",
          position: "relative",
          background: "linear-gradient(to bottom right, #4CAF50, #2196F3)",
        }}
      >
        <div
          style={{
            width: "80%",
            height: "70%",
            backgroundImage: `url(${loginImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            marginTop: "20vh",
            marginLeft: "12vh",
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              right: "10px",
              color: "lightgray",
              fontSize: "14px",
            }}
          >
            v1.0.0
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLoginForm;
