import React, { useEffect, useState } from "react";
import config from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "primereact/card";
import { Avatar } from "primereact/avatar";
import { InputText } from "primereact/inputtext";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
import axios from "axios";

const AllInfo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedUser } = location.state || {};
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedUser?._id) {
      fetchUserData();
    } else {
      setLoading(false);
      setError("No user selected");
    }
  }, [selectedUser]);

  const fetchUserData = async () => {
    try {
      setLoading(true);
      setError(null);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const userIdentifier = selectedUser.userId
        ? selectedUser.userId
        : selectedUser._id;

      const response = await axios.get(
        `${config.apiUrl}/mobile/user/details/${userIdentifier}`,
        headersConfig
      );

      setUserData(response.data);
    } catch (error) {
      setError("Failed to load user data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(`/mobile-users`);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const getInitials = (name) => {
    if (!name) return "";
    const initials = name
      .split(" ")
      .map((part) => part[0])
      .join("");
    return initials.toUpperCase();
  };

  const completedLevelsColumns = [
    { field: "syllabusName", header: "Syllabus" },
    { field: "mediumName", header: "Medium" },
    { field: "gradeName", header: "Grade" },
    { field: "levelNo", header: "Level No" },
    { field: "completedDate", header: "Completed Date" },
  ];

  const completedLevelsData = React.useMemo(() => {
    if (!userData || !Array.isArray(userData.completedLevels)) {
      return [];
    }
    return userData.completedLevels.map((level) => ({
      syllabusName: level.syllabusId?.name || "N/A",
      mediumName: level.mediumId?.name || "N/A",
      gradeName: level.gradeId?.name || "N/A",
      levelNo: level.levelNo || "N/A",
      completedDate: formatDate(level.completedDate),
    }));
  }, [userData]);

  const renderPaymentInfo = () => {
    if (userData?.paymentType === "subscription") {
      return (
        <Panel
          header="Subscription Info"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "40px",
          }}
        >
          {userData.subscriptions ? (
            <div className="p-grid p-fluid">
              <div className="p-col-12 p-md-6">
                <div className="p-field">
                  <label htmlFor="subType">Type</label>
                  <InputText
                    id="subType"
                    value={userData.subscriptions.type || "N/A"}
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="p-col-12 p-md-6">
                <div className="p-field">
                  <label htmlFor="subRegDate">Registered Date</label>
                  <InputText
                    id="subRegDate"
                    value={formatDate(userData.subscriptions.registeredDate)}
                    readOnly
                  />
                </div>
              </div>
            </div>
          ) : (
            <p>No subscription records found.</p>
          )}
        </Panel>
      );
    } else if (userData?.paymentType === "payment-gateway") {
      return (
        <Panel
          header="Payment Gateway Info"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "40px",
          }}
        >
          {userData.paymentGatewayInfo ? (
            <div className="p-grid p-fluid">
              <div className="p-col-12 p-md-6">
                <div className="p-field">
                  <label htmlFor="pgType">Type</label>
                  <InputText
                    id="pgType"
                    value={userData.paymentGatewayInfo.type || "N/A"}
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="p-col-12 p-md-6">
                <div className="p-field">
                  <label htmlFor="pgAmount">Amount</label>
                  <InputText
                    id="pgAmount"
                    value={userData.paymentGatewayInfo.amount || "N/A"}
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="p-col-12 p-md-6">
                <div className="p-field">
                  <label htmlFor="pgPurchaseDate">Purchased Date</label>
                  <InputText
                    id="pgPurchaseDate"
                    value={formatDate(userData.paymentGatewayInfo.purchaseDate)}
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="p-col-12 p-md-6">
                <div className="p-field">
                  <label htmlFor="pgExpireDate">Expire Date</label>
                  <InputText
                    id="pgExpireDate"
                    value={formatDate(userData.paymentGatewayInfo.expireDate)}
                    readOnly
                  />
                </div>
              </div>
            </div>
          ) : (
            <p>No payment gateway records found.</p>
          )}
        </Panel>
      );
    }
    return null;
  };

  if (loading || !userData) {
    return (
      <center>
        <ProgressSpinner
          style={{ width: "50px", height: "50px" }}
          strokeWidth="5"
          animationDuration=".5s"
        />
      </center>
    );
  }

  return (
    <>
      <div>
        <Button
          icon="pi pi-arrow-left"
          className="p-button-rounded p-button-text"
          onClick={handleBack}
          style={{
            color: "#708db6",
            fontSize: "0.75rem",
            float: "right",
          }}
        />
        <br />
        <br />
        <Card
          style={{
            width: "100%",
            marginTop: "20px",
            marginLeft: "20px",
            marginRight: "20px",
            background: "transparent",
            borderRadius: "1em",
            padding: "20px",
            borderLeft: "2px solid #4caf50",
            borderBottom: "2px solid #708db6",
            borderRight: "2px solid #4caf50",
            borderTop: "2px solid #708db6",
            boxShadow: "0 8px 24px #f8f8ff",
          }}
        >
          <div className="p-d-flex p-flex-column p-ai-center">
            <center>
              {userData.profilePicture &&
              typeof userData.profilePicture === "string" ? (
                <img
                  src={
                    userData.profilePicture.startsWith("http")
                      ? userData.profilePicture
                      : `${config.apiUrl}/uploads/profile-pictures/${userData.profilePicture}`
                  }
                  alt={userData.name}
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  className="p-mb-3"
                />
              ) : (
                <Avatar
                  label={getInitials(userData.name)}
                  size="xlarge"
                  shape="circle"
                  className="p-mb-3"
                  style={{
                    width: "200px",
                    height: "200px",
                    backgroundColor: "#708db6",
                    color: "#ffffff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "3rem",
                  }}
                />
              )}
            </center>

            <div
              style={{
                fontSize: "1rem",
                lineHeight: "2",
                padding: "10px",
                marginTop: "50px",
              }}
            >
              <div style={{ marginBottom: "20px" }}>
                <h3 style={{ fontStyle: "italic" }}>
                  <u>Personal Information</u>
                </h3>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "150px 1fr",
                    gap: "10px",
                  }}
                >
                  <label style={{ color: "#708db6" }}>
                    <strong>Name</strong>
                  </label>
                  <InputText value={userData.name || "N/A"} readOnly />

                  <label style={{ color: "#708db6" }}>
                    <strong>Email</strong>
                  </label>
                  <InputText value={userData.email || "N/A"} readOnly />

                  <label style={{ color: "#708db6" }}>
                    <strong>Phone</strong>
                  </label>
                  <InputText value={userData?.phone || "N/A"} readOnly />

                  <label style={{ color: "#708db6" }}>
                    <strong>Country</strong>
                  </label>
                  <InputText value={userData.country || "N/A"} readOnly />
                </div>
              </div>

              <div style={{ marginBottom: "20px", marginTop: "50px" }}>
                <h3 style={{ fontStyle: "italic" }}>
                  <u>Account Information</u>
                </h3>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "150px 1fr",
                    gap: "10px",
                  }}
                >
                  <label style={{ color: "#708db6" }}>
                    <strong>Registered Date</strong>
                  </label>
                  <InputText value={formatDate(userData.createdAt)} readOnly />
                  <label style={{ color: "#708db6" }}>
                    <strong>Platform</strong>
                  </label>
                  <InputText value={userData?.platform || "N/A"} readOnly />

                  <label style={{ color: "#708db6" }}>
                    <strong>Status</strong>
                  </label>
                  <div>
                    <Badge
                      value={userData.userStatus || "N/A"}
                      style={{
                        backgroundColor: "#708db6",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div style={{ marginBottom: "20px", marginTop: "50px" }}>
                <h3 style={{ fontStyle: "italic" }}>
                  <u>Payment Information</u>
                </h3>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "150px 1fr",
                    gap: "10px",
                  }}
                >
                  <label style={{ color: "#708db6" }}>
                    <strong>Payment Type</strong>
                  </label>
                  <div>
                    <Badge
                      value={userData?.paymentType || "N/A"}
                      style={{
                        backgroundColor: "#708db6",
                      }}
                    />
                  </div>
                  <label style={{ color: "#708db6" }}>
                    <strong></strong>
                  </label>

                  {renderPaymentInfo()}
                </div>
              </div>

              <div style={{ marginBottom: "20px", marginTop: "50px" }}>
                <h3 style={{ fontStyle: "italic" }}>
                  <u>Current Criteria Selection</u>
                </h3>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "150px 1fr",
                    gap: "10px",
                  }}
                >
                  <label style={{ color: "#708db6" }}>
                    <strong>Syllabus</strong>
                  </label>
                  <InputText
                    value={userData.syllabus?.name || "N/A"}
                    readOnly
                  />

                  <label style={{ color: "#708db6" }}>
                    <strong>Medium</strong>
                  </label>
                  <InputText value={userData.medium?.name || "N/A"} readOnly />

                  <label style={{ color: "#708db6" }}>
                    <strong>Grade</strong>
                  </label>
                  <InputText value={userData.grade?.name || "N/A"} readOnly />

                  <label style={{ color: "#708db6" }}>
                    <strong>Total Points</strong>
                  </label>
                  <InputText value={userData?.totalPoints || "N/A"} readOnly />
                </div>
              </div>

              <div style={{ marginBottom: "40px", marginTop: "50px" }}>
                <h3 style={{ fontStyle: "italic" }}>
                  <u>Completed Level Information</u>
                </h3>
                <DataTable
                  value={completedLevelsData}
                  responsiveLayout="scroll"
                >
                  {completedLevelsColumns.map((col, i) => (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                    />
                  ))}
                </DataTable>
              </div>
            </div>
          </div>
        </Card>
        <br />
        <br />
      </div>
    </>
  );
};

export default AllInfo;
