import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";
import AppMenu from "../components/AppMenu";
import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from "../store/reducers";
import AddSyllabusForm from "../features/Settings/Syllabus/AddSyllabusForm";
import Syllabus from "../features/Settings/Syllabus/Syllabus";
import AddMediumForm from "../features/Settings/Medium/AddMediumForm";
import Medium from "../features/Settings/Medium/Medium";
import AddGradeForm from "../features/Settings/Grade/AddGradeForm";
import Grade from "../features/Settings/Grade/Grade";
import AddSubjectForm from "../features/Settings/Subject/AddSubjectForm";
import Subject from "../features/Settings/Subject/Subject";
import AddQuestionForm from "../features/Questions/AddQuestionForm";
import Question from "../features/Questions/Question";
import AddPaperTypeForm from "../features/Settings/PaperType/AddPaperTypeForm";
import PaperType from "../features/Settings/PaperType/PaperType";
import AddPaperInfoForm from "../features/Settings/PaperInfo/AddPaperInfoForm";
import PaperInfo from "../features/Settings/PaperInfo/PaperInfo";
import AddBannerForm from "../features/Banners/AddBannerForm";
import Banner from "../features/Banners/Banner";
import EditSystemConfigForm from "../features/SystemConfigurations/EditSystemConfigForm";
import AppData from "../features/SystemConfigurations/AppData";
import AddUserForm from "../features/Users/AddUserForm";
import User from "../features/Users/User";
import MobileUser from "../features/MobileUsers/MobileUser";
import MobileUserCriterias from "../features/MobileUsers/MobileUserCriteriaTable";
import Leaderboard from "../features/MobileUsers/Leaderboard";
import MobileUserSubscriptions from "../features/MobileUsers/MobileUserSubscriptionTable";
import MobileUserPaymentGateways from "../features/MobileUsers/MobileUserPaymentGatewayTable";
import CompletedLevels from "../features/MobileUsers/CompletedLevels";
import AllInfo from "../features/MobileUsers/AllInfo";
import AdminLoginForm from "../features/Users/AdminLoginForm";
import Dashboard from "../features/Dashboard/DashboardPage";
import WebSettingsPage from "../features/WebSettings/WebSettingsPage";
import Profile from "../features/Users/Profile";
import Insights from "../features/Insights/InsightsPage";
import Paper from "../features/AllPapers/Papers";
import FilteredQuestions from "../features/AllPapers/FilteredQuestions";
import EditCountryOrderId from "../features/Country/EditCountryOrderId";
import CopyIDsPage from "../features/Settings/CopyIDs/CopyIDsPage";

const store = createStore(rootReducer);

const App = () => {
  const [panelMenuVisible, setPanelMenuVisible] = useState(true);
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);
  const currentUserType = localStorage.getItem("userType");
  const isNotAdmin = currentUserType !== "admin";

  return (
    <Provider store={store}>
      <Router>
        <AppContent
          panelMenuVisible={panelMenuVisible}
          setPanelMenuVisible={setPanelMenuVisible}
          isLoggedIn={isLoggedIn}
          isNotAdmin={isNotAdmin}
        />
      </Router>
    </Provider>
  );
};

const AppContent = ({
  panelMenuVisible,
  setPanelMenuVisible,
  isLoggedIn,
  isNotAdmin,
}) => {
  const location = useLocation();
  const isLoginPage = location.pathname === "/";
  const largeScreen = window.innerWidth >= 1920;

  return (
    <div
      style={{
        marginLeft:
          panelMenuVisible && !isLoginPage && isLoggedIn ? "250px" : "0",
        paddingTop: "60px",
      }}
    >
      {isLoggedIn && !isLoginPage && (
        <AppMenu
          panelMenuVisible={panelMenuVisible}
          setPanelMenuVisible={setPanelMenuVisible}
        />
      )}
      <div
        style={{
          marginLeft: "50px",
          marginTop: !isLoginPage && largeScreen ? "90px" : "40px",
          marginRight: "50px",
        }}
      >
        <Routes>
          <Route path="/" element={<AdminLoginForm />} />

          {!isNotAdmin && (
            <>
              <Route
                path="/banners"
                element={isLoggedIn ? <Banner /> : <AdminLoginForm />}
              />
              <Route
                path="/add-banner"
                element={isLoggedIn ? <AddBannerForm /> : <AdminLoginForm />}
              />
              <Route
                path="/system-configs"
                element={
                  isLoggedIn ? <EditSystemConfigForm /> : <AdminLoginForm />
                }
              />
              <Route
                path="/app-data"
                element={isLoggedIn ? <AppData /> : <AdminLoginForm />}
              />
              <Route
                path="/country"
                element={
                  isLoggedIn ? <EditCountryOrderId /> : <AdminLoginForm />
                }
              />
              <Route
                path="/add-user"
                element={isLoggedIn ? <AddUserForm /> : <AdminLoginForm />}
              />
              <Route
                path="/users"
                element={isLoggedIn ? <User /> : <AdminLoginForm />}
              />
              <Route
                path="/mobile-users"
                element={isLoggedIn ? <MobileUser /> : <AdminLoginForm />}
              />
              <Route
                path="/mobile-user-criterias"
                element={
                  isLoggedIn ? <MobileUserCriterias /> : <AdminLoginForm />
                }
              />
              <Route
                path="/mobile-user-leaderboard"
                element={isLoggedIn ? <Leaderboard /> : <AdminLoginForm />}
              />
              <Route
                path="/mobile-user-subscriptions"
                element={
                  isLoggedIn ? <MobileUserSubscriptions /> : <AdminLoginForm />
                }
              />
              <Route
                path="/mobile-user-payment-gateway"
                element={
                  isLoggedIn ? (
                    <MobileUserPaymentGateways />
                  ) : (
                    <AdminLoginForm />
                  )
                }
              />
              <Route
                path="/completed-levels"
                element={isLoggedIn ? <CompletedLevels /> : <AdminLoginForm />}
              />
              <Route
                path="/all-info"
                element={isLoggedIn ? <AllInfo /> : <AdminLoginForm />}
              />
              <Route
                path="/web-settings"
                element={isLoggedIn ? <WebSettingsPage /> : <AdminLoginForm />}
              />
              <Route
                path="/copy-ids"
                element={isLoggedIn ? <CopyIDsPage /> : <AdminLoginForm />}
              />
            </>
          )}

          <Route
            path="/syllabus"
            element={isLoggedIn ? <SyllabusComponents /> : <AdminLoginForm />}
          />
          <Route
            path="/medium"
            element={isLoggedIn ? <MediumComponents /> : <AdminLoginForm />}
          />
          <Route
            path="/grade"
            element={isLoggedIn ? <GradeComponents /> : <AdminLoginForm />}
          />
          <Route
            path="/subject"
            element={isLoggedIn ? <SubjectComponents /> : <AdminLoginForm />}
          />
          <Route
            path="/paper-type"
            element={isLoggedIn ? <PaperType /> : <AdminLoginForm />}
          />
          <Route
            path="/add-paper-type"
            element={isLoggedIn ? <AddPaperTypeForm /> : <AdminLoginForm />}
          />
          <Route
            path="/paper-info"
            element={isLoggedIn ? <PaperInfo /> : <AdminLoginForm />}
          />
          <Route
            path="/add-paper-info"
            element={isLoggedIn ? <AddPaperInfoForm /> : <AdminLoginForm />}
          />
          <Route
            path="/all-papers"
            element={isLoggedIn ? <Paper /> : <AdminLoginForm />}
          />
          <Route
            path="/filtered-questions"
            element={isLoggedIn ? <FilteredQuestions /> : <AdminLoginForm />}
          />
          <Route
            path="/insights"
            element={isLoggedIn ? <Insights /> : <AdminLoginForm />}
          />
          <Route
            path="/dashboard"
            element={isLoggedIn ? <Dashboard /> : <AdminLoginForm />}
          />
          <Route
            path="/me"
            element={isLoggedIn ? <Profile /> : <AdminLoginForm />}
          />
          <Route
            path="/questions"
            element={isLoggedIn ? <Question /> : <AdminLoginForm />}
          />
          <Route
            path="/add-question"
            element={isLoggedIn ? <AddQuestionForm /> : <AdminLoginForm />}
          />
        </Routes>
      </div>
    </div>
  );
};

const SyllabusComponents = () => {
  return (
    <div>
      <AddSyllabusForm />
      <Syllabus />
    </div>
  );
};

const MediumComponents = () => {
  return (
    <div>
      <AddMediumForm />
      <Medium />
    </div>
  );
};

const GradeComponents = () => {
  return (
    <div>
      <AddGradeForm />
      <Grade />
    </div>
  );
};

const SubjectComponents = () => {
  return (
    <div>
      <AddSubjectForm />
      <Subject />
    </div>
  );
};

export default App;
