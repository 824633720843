import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { ProgressSpinner } from "primereact/progressspinner";
import axios from "axios";
import config from "../../config";

const PlayCountLineChart = () => {
  const [dailyCounts, setDailyCounts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDailyCounts = async () => {
      try {
        const token = localStorage.getItem("token");
        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          `${config.apiUrl}/common/play/count`,
          headersConfig
        );
        setDailyCounts(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching daily play counts:", error);
        setLoading(false);
      }
    };

    fetchDailyCounts();
  }, []);

  const data = {
    labels: dailyCounts.map((item) => item.date),
    datasets: [
      {
        label: "Plays",
        data: dailyCounts.map((item) => item.count),
        fill: false,
        borderColor: "#42A5F5",
        tension: 0.5,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          text: "Completed Date",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Play Count",
        },
      },
    },
  };

  return (
    <div
      style={{
        marginBottom: "100px",
      }}
    >
      <h3
        style={{
          color: "#808080",
          marginTop: "50px",
          marginBottom: "50px",
        }}
      >
        Play Count Growth in Last 30 Days
      </h3>
      {loading ? (
        <center>
          {loading && (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="5"
              animationDuration=".5s"
            />
          )}
        </center>
      ) : (
        <Chart type="line" data={data} options={options} />
      )}
    </div>
  );
};

export default PlayCountLineChart;
