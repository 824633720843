import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { ProgressSpinner } from "primereact/progressspinner";
import { Badge } from "primereact/badge";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Paginator } from "primereact/paginator";
import { InputText } from "primereact/inputtext";
import MobileUserLineChart from "../Charts/MobileUserLineChart";
import config from "../../config";

// Table component for displaying mobile users
const MobileUserTable = ({
  users,
  totalRecords,
  first,
  setFirst,
  rows,
  setRows,
  onPageChange,
  loading,
  searchLoading,
  selectedUser,
  handleSelect,
  handleSeeAll,
  searchTerm,
  handleSearch,
  searchUsersByName,
  handleDelete,
  handleCountryChange,
  handleFromDateChange,
  handleToDateChange,
  handleClearFilter,
  handleClearSearch,
  selectedCountry,
  selectedFromDate,
  selectedToDate,
  countryCounts,
}) => {
  const [countryList, setCountryList] = useState([]);
  const today = new Date();

  useEffect(() => {
    fetchCountryList();
  }, []);

  const fetchCountryList = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/country`);
      setCountryList(
        response.data.map((country) => ({
          label: country.name,
          value: country.name,
        }))
      );
    } catch (error) {
      console.error("Error fetching country list:", error);
    }
  };

  const renderProfilePicture = (rowData) => {
    if (rowData.profilePicture && typeof rowData.profilePicture === "string") {
      return (
        <img
          src={
            rowData.profilePicture.startsWith("http")
              ? rowData.profilePicture
              : `${config.apiUrl}/uploads/profile-pictures/${rowData.profilePicture}`
          }
          alt={rowData.name}
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      );
    } else {
      return null;
    }
  };

  const renderStatus = (rowData) => {
    const statusLabel = rowData.userStatus === "active" ? "Active" : "Inactive";
    const statusSeverity =
      rowData.userStatus === "active" ? "success" : "danger";

    return <Tag value={statusLabel} severity={statusSeverity} />;
  };

  const renderPoints = (rowData) => {
    return <Badge value={rowData.totalPoints} severity="info"></Badge>;
  };

  const formatDateForDisplay = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <>
      <h3
        style={{
          float: "left",
          color: "#808080",
        }}
      >
        Mobile User List
      </h3>
      <br />
      <br />
      <br />
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "40px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Dropdown
            value={selectedCountry}
            options={countryList}
            onChange={handleCountryChange}
            placeholder="Country"
            showClear
            filter
          />
          <Calendar
            value={selectedFromDate}
            onChange={handleFromDateChange}
            placeholder="From Date"
            dateFormat="yy-mm-dd"
            maxDate={today}
          />
          <Calendar
            value={selectedToDate}
            onChange={handleToDateChange}
            placeholder="To Date"
            dateFormat="yy-mm-dd"
            maxDate={today}
          />
          <Button
            icon="pi pi-filter-slash"
            className="p-button-rounded"
            style={{
              background: "none",
              border: "none",
              borderColor: "#708db6",
              color: "#708db6",
              fontSize: "0.5rem",
            }}
            onClick={handleClearFilter}
          />
        </div>

        <Card
          style={{
            backgroundColor: "#eff3f8",
            borderRadius: "10px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            color: "#708db6",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 10px",
            height: "60px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>
              <b>Users</b>
            </span>
            &nbsp;&nbsp;
            <Badge
              value={totalRecords}
              size="large"
              severity="info"
              style={{ fontWeight: "bold" }}
            />
          </div>
        </Card>
      </div>

      <div
        style={{
          display: "flex",
        }}
      >
        <InputText
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search by name"
        />
        &nbsp;
        <Button
          label={
            searchLoading ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                Searching...&nbsp;&nbsp;
                <ProgressSpinner
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                />
              </div>
            ) : (
              "Search"
            )
          }
          style={{
            background: "#708db6",
            border: "none",
            color: "white",
            fontSize: "1rem",
            marginLeft: "8px",
          }}
          onClick={searchUsersByName}
        />
        &nbsp;&nbsp;
        <Button
          icon="pi pi-filter-slash"
          className="p-button-rounded"
          style={{
            background: "none",
            border: "none",
            borderColor: "#708db6",
            color: "#708db6",
            fontSize: "0.5rem",
          }}
          onClick={handleClearSearch}
        />
      </div>
      <br />
      <br />

      <div className="table-container">
        <DataTable
          value={users}
          selectionMode="single"
          selection={selectedUser}
          style={{ marginBottom: "50px" }}
        >
          <Column field="country" header="Country" />
          <Column field="name" header="Name" />
          <Column field="email" header="Email" />
          <Column field="phone" header="Phone" />
          {/* <Column
            field="profilePicture"
            header="Picture"
            body={renderProfilePicture}
          /> */}
          <Column field="paymentType" header="Payment Type" />
          <Column field="platform" header="Platform" />
          <Column field="userStatus" header="Status" body={renderStatus} />
          <Column
            field="totalPoints"
            header="Total Points"
            body={renderPoints}
          />
          <Column
            field="createdAt"
            header="Registered Date"
            body={(rowData) => formatDateForDisplay(rowData.createdAt)}
          />
          <Column
            header="Actions"
            body={(rowData) => (
              <div>
                <Button
                  icon="pi pi-angle-double-right"
                  className="p-button-rounded"
                  onClick={() => handleSelect(rowData)}
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#708db6",
                    border: "#708db6",
                  }}
                />
                &nbsp;
                <Button
                  icon="pi pi-user"
                  className="p-button-rounded"
                  onClick={() => handleSeeAll(rowData)}
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#4caf50",
                    border: "#708db6",
                  }}
                />
                {/* &nbsp;&nbsp;
                <Button
                  icon="pi pi-trash"
                  className="p-button-rounded p-button-danger"
                  onClick={() => handleDelete(rowData)}
                /> */}
              </div>
            )}
          />
        </DataTable>
        <center>
          {loading && (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="5"
              animationDuration=".5s"
            />
          )}
        </center>
        <Paginator
          first={first}
          rows={rows}
          totalRecords={totalRecords}
          onPageChange={(e) => {
            setFirst(e.first);
            setRows(e.rows);
            onPageChange(e);
          }}
        />
      </div>
      <MobileUserLineChart />
      <h3
        style={{
          float: "left",
          color: "#808080",
        }}
      >
        Mobile User Count and Total Points by Country
      </h3>
      <br />
      <br />
      <div style={{ marginTop: "50px", marginBottom: "100px" }}>
        <DataTable value={countryCounts} responsiveLayout="scroll">
          <Column field="_id" header="Country"></Column>
          <Column field="count" header="Mobile User Count" sortable></Column>
          <Column field="totalPoints" header="Total Points" sortable></Column>
        </DataTable>
      </div>
    </>
  );
};

export default MobileUserTable;
